import React from 'react';
import { Icon } from 'antd';

import * as sassVars from '../style/_common.scss';
import { gasend } from '../util';

const HeroSection = () => {
  return (
    <div
      className="section fullpage bl70 row jc ac wrap"
      style={{
        padding: '0px',
        backgroundImage: "url('/img/hero.jpg')",
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}>
      <div className="col jc ae fl1" style={{ padding: 'calc(2vw + 10px)' }}>
        <p
          className="tf bold fs34 tar bl100 readable"
          style={{
            textShadow: 'rgba(255,255,255, 0.5) 0px 0px 80px, rgba(255,255,255, 0.2) 0px 0px 20px',
            lineHeight: '1.2'
          }}>
          Join the Direct Primary Care revolution.
        </p>
        <br />
        <br />
        <p className="bl100 fs14 tar readable" style={{ opacity: 1, maxWidth: '550px' }}>
          {`Step outside the broken healthcare system. Get the care you deserve at a fair, transparent price. Join 250,000 other Americans who are already benefitting from direct primary care.`}
        </p>

        <br />
        <br />
        <div className="row je ac wrap">
          <a
            href="https://www.facebook.com/groups/dpcnation"
            target="_blank"
            rel="noopener noreferrer"
            className="mt8 mr8"
            style={{
              boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 25px'
            }}>
            <span className="btn solidwhite">
              <Icon
                type="facebook"
                theme="filled"
                style={{ fontSize: '21px', marginRight: '2px' }}
              />
              {'  '}
              <span className="fs14">Join the DPC Nation</span>
            </span>
          </a>
          <span style={{ height: '8px' }} />
          <a
            href="https://mapper.dpcfrontier.com"
            target="_blank"
            rel="noopener noreferrer"
            className="mt8 mr8"
            onClick={gasend('find_a_practice')}
            style={{
              boxShadow: 'rgba(0, 0, 0, 0.4) 0px 0px 25px'
            }}>
            <span className="btn solid">
              <span className="fs14">Find a Practice</span>
            </span>
          </a>
        </div>
      </div>

      <div
        className="row jc ac ts8 sm-hide"
        style={{ position: 'absolute', bottom: '9px', left: 'calc(50vw - 50px)', width: '100px' }}>
        <Icon
          type="down-circle"
          style={{ fontSize: '36px', color: sassVars.mainColor, opacity: 0.3 }}
          size="large"
        />
      </div>
    </div>
  );
};

export default HeroSection;

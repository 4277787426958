import React from 'react';

import Head from '../components/Head';

import StandardPage from '../components/StandardPage';
import HeroSection from '../components/HeroSection';
import LetterSection from '../components/LetterSection';

import '../style/_common.scss';

class Home extends React.Component {
  render() {
    // const { email } = this.state;
    return (
      <StandardPage>
        <Head
          title="What is Direct Primary Care?"
          description="DPC Nation is an educational resource for patients who are tired of suffering in a broken healthcare system. Direct primary care has already helped over a quarter million Americans stay healthier, save money, and form a real relationship with their doctor."
          url=""
          image="/img/banner.png"
        />

        <HeroSection />
        <LetterSection />
      </StandardPage>
    );
  }
}

export default Home;

import React from 'react';

export const LinkCard = props => {
  return (
    <div style={{ minWidth: '250px' }} className="wide card">
      <a href={props.link}>
        <h3 className="subsubtitle">{props.title}</h3>
        <p>{props.description}</p>
      </a>
    </div>
  );
};

export default function LearnMore(props) {
  return (
    <div>
      <h2 className="subtitle">Learn more</h2>
      {props.home && (
        <LinkCard
          link="/"
          title="What is Direct Primary Care?"
          description="Learn about a new and better way to do healthcare."
        />
      )}
      {props.whatisdpc && (
        <LinkCard
          link="/"
          title="What is DPC?"
          description="We answer the most frequently asked question of all!"
        />
      )}
      {props.essays && (
        <LinkCard
          link="/essays/"
          title="What is DPC?"
          description="We answer the most frequently asked question of all!"
        />
      )}
      {props.crisis && (
        <LinkCard
          link="/essays/dpc-solves-the-healthcare-crisis/"
          title="How DPC Solves the Healthcare Crisis"
          description="Insurance and coding and hospital consolidation, oh my!"
        />
      )}
      {props.market && (
        <LinkCard
          link="/essays/the-healthcare-market/"
          title={'The Sad State of the Healthcare "Market"'}
          description="And how DPC brings the free market back into healthcare."
        />
      )}
      {props.faq && (
        <LinkCard
          link="/faq/"
          title="Frequently Asked Questions"
          description="Everything you want to know about DPC, pricing, insurance, and more."
        />
      )}
    </div>
  );
}

/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Anchor } from 'antd';
import CTASection from './CTASection';
import LearnMore from './LearnMore';
const { Link } = Anchor;

const LetterSection = () => {
  const anchorStyle = { visibility: 'hidden', cursor: 'none' };
  return (
    <div className="col ac jc wrap" style={{ backgroundColor: 'white', padding: '100px 0px' }}>
      <a id="letter" style={anchorStyle} />
      <div className="row as jc wide">
        <div className="gutter">
          {false && (
            <Anchor offsetTop={150}>
              <Link href="#intro" title={'DPC in a nutshell'} />
              <Link href="#crisis" title={'The healthcare crisis'}>
                <Link href="#howinsuranceworks" title="The business of insurance" />
                <Link href="#overcoverage" title="Over-coverage" />
                {/* </Link>
            <Link href="#hospitals" title={'How hospitals ruin everything'}> */}
                <Link href="#coding" title={'The birth of medical coding'} />
                <Link href="#gaming" title={'Gaming the system'} />
                <Link href="#overhead" title={'The rise of the administrators'} />
                <Link href="#consolidation" title={'Hospital consolidation'} />
                <Link href="#prices" title={'Why prices are going up'} />
              </Link>
              <Link href="#victims" title={'The victims of the crisis'}>
                <Link href="#patients" title={'Patients'} />
                <Link href="#doctors" title={'Doctors'} />
              </Link>
              <Link href="#faq" title={'DPC FAQ'}>
                <Link href="#dpc" title={'What is DPC?'} />
                <Link href="#benefits" title={'What are the benefits of membership?'} />
                <Link href="#expensive" title={'Is it expensive?'} />
                <Link href="#whymembership" title={'Why is it membership-based?'} />
                <Link href="#concierge" title={'Is it the same as concierge?'} />
                <Link href="#needinsurance" title={'Do I need insurance?'} />
              </Link>
              <Link href="#cta" title={'Getting started with DPC'} />
            </Anchor>
          )}
        </div>
        <div className="wide readable col js ac blog">
          <a id="intro" style={anchorStyle} />
          <h1 className="wide tac fs28 mv32">What is direct primary care?</h1>
          <p>
            Direct primary care, or DPC for short, is a simple idea that solves a very complex
            problem.
          </p>
          <p>
            Here's a thought experiment: what if you took all the worst parts about healthcare in
            America — insurance companies and their mountains of paperwork, greedy corporate
            hospital systems that don't care about patient satisfaction, and all the shadowy
            middlemen trying to eliminate price transparency — and you threw them all out the
            window. That's DPC in a nutshell.
          </p>
          <p>
            It's clear that the current system doesn't care about providing great care. Have you
            ever scheduled an appointment months in advance, only to sit in the waiting room for an
            hour, followed by an unsatifying 10 minute doctor visit? That's par for the course these
            days.
          </p>
          <p>DPC is different.</p>
          <p>
            As a DPC patient, you forge a relationship with a doctor that knows you. No more
            scheduling months in advance; your DPC doctor is at your fingertips. If you need an
            in-person visit, you can usually book one the very next day. Your doctor will stay on
            top of your health: running preventative tests, diagnosing you when you're sick,
            prescribing medicines (and often dispensing them to you directly at wholesale prices —
            no extra trip to the pharmacy!), negotiating low prices on blood tests and scans,
            quickly addressing <em>any</em> health concerns or questions you have, and—if
            necessary—coordinating your care with specialists and hospitals. The vast majority of
            DPC doctors give you their cell phone number so you can text them with any questions!
          </p>

          <p>
            How is this possible? By cutting insurance out of the picture. Insurance is
            fundamentally incompatible with providing comprehensive, compassionate care. When a
            doctor bills your insurance, they are forced to submit an itemized list of each and
            every medical service they provided. If they don't, they won't get paid. But—surprise,
            surprise—no insurance company will pay a doctor for answering one of your texts,
            reminding you to avoid red meat, answering on the first ring when you call, opening the
            door for you when you arrive at the office, or asking about your family!
          </p>
          <p>
            In DPC, you pay a flat monthly membership fee, usually around the size of your cell
            phone bill. You can pay with your credit card—no more clipboards with pages of insurance
            paperwork.
          </p>
          <p>
            If it seems like everything is a subscription these days, that’s because it’s the best
            way for a business to offer a high-quality service and excellent support over a long
            period of time. What if Netflix nickel-and-dimed you for every episode of Stranger
            Things you watched? It would be awful, that’s what. DPC is like Netflix: you get access
            to nearly unlimited care for one low monthly price.
          </p>
          <p>
            This is healthcare like you've never experienced it. It's like having a doctor in the
            family.
          </p>
          <p>
            Want to learn more? Reach out to a DPC doctor in your area! Click "Find a Practice"
            below to get started.
          </p>

          <div style={{ height: '100px' }} />
          <CTASection />
          <div style={{ height: '100px' }} />
          <LearnMore crisis market faq />
        </div>
        <div className="gutter" />
      </div>
    </div>
  );
};

export default LetterSection;
